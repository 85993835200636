import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center my-40" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingOutlined = _resolveComponent("LoadingOutlined")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_LoadingOutlined, { class: "text-7xl mb-4" }),
    _createVNode(_component_a_typography_paragraph, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t("Logging in with Google...")), 1)
      ]),
      _: 1
    })
  ]))
}