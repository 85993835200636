
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { LoadingOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  setup() {
    const { t } = useI18n();

    return { t };
  },
  components: { LoadingOutlined },
});
